<template>
  <div>
    <loader v-if="loading" />
    <template v-else>

      <div class="mb-4">
        <!-- Info Guide -->
        <info-guide
          v-if="guideVisibleFirstTime"
          type="employeesAndPayrollExamples"
          visible
          @click="closeInfoFirstTime"
        />
        <!-- /Info Guide -->

        <div class="mb-3 margin-guide-not-visible">
          <div class="d-flex justify-content-end">
            <erase-icon-button
              v-if="removeButtonVisible"
              @click="onDelete"
            />
          </div>
          <div class="d-flex justify-content-end mt-3">
            <set-labor-burden
              :disabled="!canEdit"
              @on-save="onUpdate"
            />
          </div>
        </div>
      </div>

      <template v-if="!isStaffAndPayrollEmpty">
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <distribution-of-payroll-expenses />
        </ib-card>
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <payroll-expenses-over-time />
        </ib-card>
        <ib-card
          :animation="false"
          class="pt-4 pb-2"
        >
          <staff-and-payroll-table />
        </ib-card>
      </template>
      <empty-placeholder v-else />
    </template>

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="employeesAndPayrollExamples"
      :visible="guideVisible"
      @close="guideVisible = false;"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="staffAndPayroll"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Add Staff And Payroll-->
    <dialog-add-staff
      v-if="canEdit"
      :dialog-add-staff-visible="dialogAddNewVisible"
      @close-dialog-add-staff="dialogAddNewVisible = false"
      @close-dialog-on-button="dialogAddNewVisible = false"
      @on-update-staff-and-payroll-view="onUpdate"
    />
    <!-- /Add Staff And Payroll -->
  </div>
</template>

<script>
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import DialogAddStaff from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/Dialogs/DialogAddStaff'
import DistributionOfPayrollExpenses from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/PayrollExpense/DistributionOfPayrollExpenses'
import EmptyPlaceholder from '@/views/Home/StoryMode/FinancialPlan/Components/EmptyPlaceholder'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import EventBus from '@/event-bus'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'
import Loader from '@/components/Loader'
import MixinAiBuddyDrawer from '@/mixins/aiBuddyDrawer'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import PayrollExpensesOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/PayrollExpense/PayrollExpensesOverTime'
import SetLaborBurden from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/SetLaborBurden'
import StaffAndPayrollTable from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/Tables/StaffAndPayrollTable'
import { mapState } from 'vuex'

export default {
  name: 'ExpensesStaffAndPayroll',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    DialogAddStaff,
    DistributionOfPayrollExpenses,
    EmptyPlaceholder,
    EraseIconButton,
    Loader,
    PayrollExpensesOverTime,
    SetLaborBurden,
    StaffAndPayrollTable
  },

  mixins: [MixinApiCalculations, MixinGuide, MixinIdeaRoles, MixinDeleteIdeaExamples, MixinAiBuddyDrawer],

  data () {
    return {
      loading: false,
      dialogAddNewVisible: false
    }
  },

  computed: {
    ...mapState(['idea']),

    isStaffAndPayrollEmpty () {
      return this.idea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length === 0
    },

    removeButtonVisible () {
      return this.canEdit && this.staffAndPayrollIHasItemsFromTemplate
    }
  },

  mounted () {
    this.openGuide('staffPayrollFinancialPlanGuide')
    EventBus.$on('add-edit', () => {
      this.onShowAddNewDialog()
    })

    EventBus.$on('open-info', () => {
      this.guideVisible = true
    })

    EventBus.$on('close-info-first-time', () => {
      this.guideVisibleFirstTime = false
    })

    EventBus.$on('remove-examples', () => {
      this.deleteExamples()
    })
  },

  beforeDestroy () {
    EventBus.$off('add-edit')
    EventBus.$off('open-info')
    EventBus.$off('remove-examples')
    EventBus.$off('close-info-first-time')
  },

  methods: {
    onShowAddNewDialog () {
      this.getStaffAndPayroll()
      this.dialogAddNewVisible = true
    },

    onSaveLaborBurden () {
      this.loading = true
      this.getStaffAndPayroll()
        .finally(_ => { this.loading = false })
    },

    onUpdate () {
      this.loading = true
      Promise.all([
        this.getStaffAndPayroll(),
        this.getTotalCosts(),
        this.getProfitAndCashFlow()
      ])
        .then(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteExamples () {
      this.deleteExamplesMixin('staff-and-payrolls')
        .then(_ => { this.onUpdate() })
    }
  }
}
</script>
